const createNewProject = async(projectTitle, setProjects) =>{
    const token = localStorage.getItem('cooppStoryboardGenerator_AuthToken')
    try{
        const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'
        const response = await fetch(`${API_BASE_URL}/createProject`, {
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({projectTitle, token}),
        });
        const result = await response.json();
        // add the new project to the projects array
        setProjects((projects) => [...projects, result]);
    } catch(error){
        console.error('Error: ', error)
    }
}

export default createNewProject;